import React from "react";
import "./Userlist.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Table1 from "../../components/table/Table1";

function Businesslist() {
  return (
    <div className="userlist">
      <Sidebar />
      <div className="userlistContainer">
        <Navbar />
        <Table1 />
      </div>
    </div>
  );
}

export default Businesslist;
