import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./table1.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import { HighlightTwoTone } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#7451f8",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    width: "400px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 1,
  },
}));
// const StyledTableRow= styled(tableRow)(({theme})=>
// ({
//   ""
// }))

// function createData(name, userEmail, fat, carbs, protein) {
//   return { name, userEmail, fat, carbs, protein };
// }

// const rows = [
//   createData("Frozen yoghurt", "Harshal@gmail.com"),
//   createData("Ice cream sandwich", "shubham@gmail.com"),
// ];

export default function Tables1() {
  const [users, setUsers] = useState([])
  useEffect (() => {
      axios.get("https://server.maxtechworld.com/getUsers")
      .then(users => setUsers(users.data))
      .catch(err => console.log(err))
  }, [])
  return (
    <div className="">
      <h3 className="users">USER LIST</h3>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 600 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">User</StyledTableCell>
              <StyledTableCell align="center">User Email</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <StyledTableRow key={user.userName}>
                <StyledTableCell align="center" component="th" scope="row">
                  {user.userName}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {user.email}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
