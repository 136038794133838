import React from "react";
import "./Businesslist.scss"
import Tables2 from "../../components/table/table2";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";

function Businesslist() {
  return (
    <div className="businesslist">
      <Sidebar />
      <div className="businesslistContainer">
        <Navbar />
        <Tables2/>
      </div>
    </div>
  );
}

export default Businesslist;
