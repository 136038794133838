import React, { useState } from "react"
import axios from "axios"
import { useNavigate, Link } from "react-router-dom"
import "./login.scss"


function Signup() {
    const history = useNavigate();

    const[userName, setUserName] = useState('')
    const [email,setEmail]=useState('')
    const [password,setPassword]=useState('')

    async function submit(e){
        e.preventDefault();

        try{

            await axios.post("https://server.maxtechworld.com/signup",{
                userName,email,password
            })
            .then(res=>{
                if(res.data === "exist"){
                    alert("User already exists")
                }
                else if(res.data === "notexist"){
                    history("/home",{state:{id:email}})
                }
            })
            .catch(e=>{
                alert("wrong details")
                console.log(e);
            })

        }
        catch(e){
            console.log(e);

        }

    }


    return (
        <div className="container">
        <input type="checkbox" id="check"/>
        <div className="login form">
        <header>Sign-Up</header>
        <form action="POST">
        <input type="text" onChange={(e) => { setUserName(e.target.value) }} placeholder="Enter your Full Name"/>
        <input type="email" onChange={(e) => { setEmail(e.target.value) }} placeholder="Enter your email"/>
        <input type="password" onChange={(e) => { setPassword(e.target.value) }} placeholder="Enter your password"/>
        <input type="button" className="button" value="Sign-Up" onClick={submit} />
        </form>
        <div className="signup">
        <span className="signup">Already have an account?
        <Link to="/">Login</Link>
        </span>
        </div>
    </div>
    </div>
    )
}

export default Signup