import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./table2.scss";
import { useEffect, useState } from "react";
import axios from "axios";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#7451f8",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

// function createData(
//   businessName,
//   ownerFirstName,
//   ownerLastName,
//   ownerPhoneNumber,
//   ownerEmail
// ) {
//   return {
//     businessName,
//     ownerFirstName,
//     ownerLastName,
//     ownerPhoneNumber,
//     ownerEmail,
//   };
// }

// const rows = [
//   createData(
//     "Plumbing Universe",
//     "Ramesh",
//     "Patel",
//     "987654321",
//     "ramesh@gmail.com"
//   ),
//   createData(
//     "Plumbing Universe",
//     "Ramesh",
//     "Patel",
//     "987654321",
//     "ramesh@gmail.com"
//   ),
//   createData(
//     "Plumbing Universe",
//     "Ramesh",
//     "Patel",
//     "987654321",
//     "ramesh@gmail.com"
//   ),
//   createData(
//     "Plumbing Universe",
//     "Ramesh",
//     "Patel",
//     "987654321",
//     "ramesh@gmail.com"
//   ),
// ];

export default function CustomizedTables() {
  const [owners, setOwners] = useState([])
  useEffect(() => {
      axios.get("https://server.maxtechworld.com/getOwners")
      .then((owners) => setOwners(owners.data))
      .catch(err => console.log(err))
  },[])
  return (
    <div className="businessuserlist">
      <h3 className="businessusers">BUSINESS USER LIST</h3>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Busness Name</StyledTableCell>
              <StyledTableCell align="right">Owner First Name</StyledTableCell>
              <StyledTableCell align="right">OwnerLastName</StyledTableCell>
              <StyledTableCell align="right">
                Owner Phone Number
              </StyledTableCell>
              <StyledTableCell align="right">Owner Email ID</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {owners.map((owner) => (
              <StyledTableRow key={owner.businessName}>
                <StyledTableCell component="th" scope="row">
                  {owner.businessName}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {owner.ownerFirstName}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {owner.ownerLastName}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {owner.ownerNumber}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {owner.email}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
