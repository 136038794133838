import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import BusinessIcon from "@mui/icons-material/Business";
import HomeIcon from "@mui/icons-material/Home";
import WorkIcon from "@mui/icons-material/Work";
import CampaignIcon from "@mui/icons-material/Campaign";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ContactsIcon from "@mui/icons-material/Contacts";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link } from "react-router-dom";


const Sidebar = () => {
  
  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/home" style={{ textDecoration: "none" }}>
          <span className="logo">Admin</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <Link to="/home" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </Link>
          <Link to="/getUsers" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Users</span>
            </li>
          </Link>
          <Link to="/getOwners" style={{ textDecoration: "none" }}>
            <li>
              <BusinessIcon className="icon" />
              <span>Business Properties</span>
            </li>
          </Link>
          <Link to="/home" style={{ textDecoration: "none" }}>
            <li>
              <HomeIcon className="icon" />
              <span>Rental Properties</span>
            </li>
          </Link>
          <Link to="/home" style={{ textDecoration: "none" }}>
            <li>
              <LocalShippingIcon className="icon" />
              <span>Packages</span>
            </li>
          </Link>
          <Link to="/home" style={{ textDecoration: "none" }}>
            <li>
              <WorkIcon className="icon" />
              <span>Job</span>
            </li>
          </Link>
          <Link to="/home" style={{ textDecoration: "none" }}>
            <li>
              <CampaignIcon className="icon" />
              <span>Advertisement</span>
            </li>
          </Link>
          <Link to="/home" style={{ textDecoration: "none" }}>
            <li>
              <ContactsIcon className="icon" />
              <span>Candidates</span>
            </li>
          </Link>
          <Link to="/home" style={{ textDecoration: "none" }}>
            <li>
              <ManageAccountsIcon className="icon" />
              <span>Employers</span>
            </li>
          </Link>
          <p className="title">USER</p>
          <li>
            <AccountCircleOutlinedIcon className="icon" />
            <span>Profile</span>
          </li>
          <li>
            <ExitToAppIcon className="icon" />
            <span>Logout</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
