import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Userlist from "./pages/userslist/Userlist";
import Businesslist from "./pages/Businesslist/Businesslist"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import Signup from "./pages/login/Signup";

function App() {
  const { darkMode } = useContext(DarkModeContext);

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        {/* <Routes>
          <Route path="/" element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="users" element={<Userlist  />} />
          <Route path="getOwners" element={<Businesslist />} />
          <Route />
        </Routes> */}
        <Routes>
          <Route path="/" element={<Login/>}/>
          <Route path="/signup" element={<Signup/>}/>
          <Route path="/home" element={<Home/>}/>
          <Route path="/getUsers" element = {<Userlist />} />
          <Route path="/getOwners" element = {<Businesslist />} />
        </Routes>
      
      </BrowserRouter>
    </div>
  );
}

export default App;
