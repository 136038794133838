import React, { useState } from "react"
import axios from "axios"
import { useNavigate, Link } from "react-router-dom"
import "./login.scss"


function Login() {

    const history = useNavigate();

    const [email,setEmail]=useState('')
    const [password,setPassword]=useState('')

    async function submit(e){
        e.preventDefault();

        try{

            await axios.post("https://server.maxtechworld.com/",{
                email,password
            })
            .then(res=>{
                if(res.data === "exist"){
                    history("/home",{state:{id:email}})
                }
                else if(res.data === "wrongpassword"){
                    alert("Wrong Password")
                }
                else if(res.data === "notexist"){
                    alert("User have not sign up")
                }
            })
            .catch(e=>{
                alert("wrong details")
                console.log(e);
            })

        }
        catch(e){
            console.log(e);

        }

    }


    return (
        <div className="container">
            <input type="checkbox" id="check"/>
            <div className="login form">
            <header>Login</header>
            <form action="POST">
            <input type="email" onChange={(e) => { setEmail(e.target.value) }} placeholder="Enter your email"/>
            <input type="password" onChange={(e) => { setPassword(e.target.value) }} placeholder="Enter your password"/>
            <input type="button" className="button" value="Login" onClick={submit} />
            </form>
            <div className="signup">
            <span className="signup">Don't have an account?
            <Link to="/signup">Signup Page</Link>
            </span>
            </div>
        </div>
        </div>
        
    )
}

export default Login